import { CheckPermissionContainer } from 'components';
import React from 'react';

export const checkPermissions = (userPermissionIds?: number[], action?: number) => {
  if (userPermissionIds?.find((o) => o === action)) {
    return true;
  }
  return false;
};

export const checkSomePermissions = (userPermissionIds: number[], actions: number[]) => {
  const isSomePermissions = actions?.some((permission) => checkPermissions(userPermissionIds, permission));
  return isSomePermissions;
};

export function checkPermissionWrapper(children: (props: any) => React.JSX.Element, permission: number) {
  const parent = (props: any) => {
    return (
      <CheckPermissionContainer permission={permission}>
        {React.createElement(children, props)}
      </CheckPermissionContainer>
    );
  };
  parent.displayName = 'checkPermissionWrapper';
  return parent;
}
